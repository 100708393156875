::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: all 0.4s;
  border-radius: 20px;
  background-color: #e7e9ed;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  transition: all 0.4s;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:horizontal:hover {
  cursor: pointer;
  height: 10px;
  width: 10px;
  border-radius: 40px;
}